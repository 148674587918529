import configuration from './configuration.json';
import { getStore } from './ApplicationState';
import { EntryStates } from './EntryState';
import { myBrowserHistory } from './ApplicationState';
import { LoginState } from './UserManagement';
import { Auth } from 'aws-amplify';
import { GetCountryOriginResult } from './peekapak-types/DataProtocolTypes';
import { getCountryOriginBasedOnIp as getCountry } from './BackendInterface';
export function getConfiguration(configurationKey: string) {
  if (!isConfigurationKeyExists()) {
    throw new Error(
      `getConfiguration: configuration file does not have an entry for '${configurationKey}'`,
    );
  }

  if (import.meta.env.REACT_APP_TEST_PROD_API) {
    return configuration.prod[configurationKey];
  } else if (
    import.meta.env.MODE === 'development' ||
    import.meta.env.REACT_APP_USE_DEV_API
  ) {
    return configuration.dev[configurationKey];
  } else {
    return configuration.prod[configurationKey];
  }

  function isConfigurationKeyExists() {
    return (
      configuration.dev.hasOwnProperty(configurationKey) ||
      configuration.prod.hasOwnProperty(configurationKey)
    );
  }
}
export function noWhiteSpace(
  strings: Array<string>,
  ...placeholders: Array<string>
) {
  // Build the string as normal, combining all the strings and placeholders:
  const withSpace = strings.reduce(
    (result, string, i) => result + placeholders[i - 1] + string,
  );
  const withoutSpace = withSpace.replace(/\s\s+/g, ' ');
  return withoutSpace;
}

export const setCookie = (
  cname: string,
  cvalue: string,
  exdays?: number,
  path?: string,
) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays ?? 1) * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;

  const encodedValue = fixedEncodeURIComponent(cvalue);
  // console.debug( 'encodedValue', encodedValue );
  document.cookie = `${cname}=${encodedValue}; ${expires}${
    path ? `;path=${path};domain=peekapak.com` : ';path=/;'
  }`;
};

//
// additionally encodes characters that are not delimiters but
// are nonetheless reserved in RFC 3986
export function fixedEncodeURIComponent(str: string) {
  return encodeURIComponent(str).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16)}`,
  );
}

export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      const decodedValue = decodeURIComponent(
        c.substring(name.length, c.length),
      );
      return decodedValue;
    }
  }

  return '';
};
export const deleteCookie = (cname: string) => {
  document.cookie = `${cname}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
export function isUserAuthenticated() {
  const { user } = getStore().getState();
  return user.cognitoProfile !== null && user.userProfile !== null;
}
export function isEntryThroughTryout() {
  const { entry } = getStore().getState();
  return entry.entryState === EntryStates.tryoutMode;
}

function expireCurrentSession() {
  if (window.location.pathname !== '/sessionExpired') {
    setCookie('peekapak.pathBeforeSessionExpired', window.location.pathname, 7);
  }
}

export function expireSessionAndRedirect(
  isRedirectToExpiredSessionPage: boolean = true,
) {
  // console.debug( `expireSessionAndRedirect called` );
  // console.trace();
  expireCurrentSession();

  if (isRedirectToExpiredSessionPage) {
    myBrowserHistory.push('/sessionExpired');
  }
}
export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
export const getPossessiveForm = (name) => {
  if (name.toLowerCase().slice(-1) === 's') {
    return `${name}’`;
  }

  return `${name}’s`;
};

export const quitGame = async (afterQuitCallback) => {
  console.debug('quitGame called');

  if (!peekavilleWebContainer.gameInstance) {
    return;
  }

  if (afterQuitCallback) {
    afterQuitCallback();
  }
  peekavilleWebContainer.signalPlayerSignout();
  /*
   * This code currently causes 100% CPU usage  in Chrome
   * This will apprently be fixed in a future version beyond
   * 2020.3
   *
  peekavilleWebContainer.gameInstance.Quit(() => {
    peekavilleWebContainer.gameInstance = null;
    if (afterQuitCallback) {
      afterQuitCallback();
    }
  });
  */
};

export const logout = async (afterQuickCallback) => {
  const { user } = getStore().getState();
  if (
    user.loginState === LoginState.loggedIn ||
    user.loginState === LoginState.authenticated
  ) {
    Auth.signOut()
      .then(() => {
        if (afterQuickCallback) {
          afterQuickCallback();
        }
      })
      .catch((error) => {
        // TODO: implement sentry in myPeekaville
        // logger.logException( error );
      });
  }
};

export function getCleverProfile(cleverId: string, cleverAccessToken: string) {
  return new Promise((resolve, reject) => {
    const authorizationString = `Bearer ${cleverAccessToken}`;
    fetch(`https://api.clever.com/v3.0/users/${cleverId}`, {
      method: 'get',
      headers: {
        Authorization: authorizationString,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return resolve(json);
      })
      .catch((error) => reject(error));
  });
}

export function isTokenExpired(expiry: number, buffer = 0) {
  // if token is expired or within a buffer period of expiring
  const expired = Math.floor(Date.now() / 1000) - expiry >= -buffer;
  // console.debug(
  //   `isTokenExired `,
  //   expired,
  //   ' expires at  ',
  //   getReadableUnixTimeAnsi(expiry * 1000),
  //   ' current = ',
  //   Math.floor(Date.now() / 1000),
  //   ' expires = ',
  //   expiry
  // );
  return expired;
}

type VisitorOriginDataCookie = GetCountryOriginResult & {
  createdAt: number;
};

export async function getVisitorOriginBasedOnIp(): Promise<GetCountryOriginResult> {
  const existingRecordJSON = getCookie('peekapak.visitorOrigin');
  try {
    const existingRecord: VisitorOriginDataCookie =
      JSON.parse(existingRecordJSON);

    if (existingRecord.createdAt) {
      if (Date.now() - existingRecord.createdAt < 1000 * 3600 * 24) {
        return existingRecord;
      }
    }
  } catch (error) {
    const warningMessage = `Error detected in getting cookie content, but will call backend to recover: ${
      (error as Error).message
    }`;
    console.warn(warningMessage);
  }

  try {
    const data: GetCountryOriginResult = await getCountry();
    const persist = { ...data, createdAt: Date.now() };
    setCookie('peekapak.visitorOrigin', JSON.stringify(persist));
    return data;
  } catch (_error) {
    const warningMessage = `Error trying to get visitor origin, defaulting to US: ${JSON.stringify(
      _error,
      null,
      2,
    )}`;
    console.warn(warningMessage);
    return {
      country_code: 'US',
      country: 'United States',
      region_code: 'Unknown',
      region: 'Unknown',
      sourceIp: 'Unknown',
    };
  }
}

export async function getBasePath(startingPoint: string = '/') {
  const result = await getVisitorOriginBasedOnIp();
  const isUae = result.country === 'United Arab Emirates';

  if (startingPoint.includes('/play')) {
    if (IsIOS()) {
      if (isUae) {
        return '/mena/play/ios/';
      }

      return '/play/ios/';
    } else if (IsAndroid()) {
      if (isUae) {
        return '/mena/play/android/';
      }
      return '/play/android/';
    }

    if (isUae) {
      return '/mena/play/';
    }

    return '/play/';
  } else {
    if (IsIOS()) {
      return '/ios/';
    } else if (IsAndroid()) {
      return '/android/';
    }
    return '/';
  }
}
